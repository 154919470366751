import React from 'react';
import AdminPanel from 'components/block-elements/admin-panel/admin-pane';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';

const AdminPanelBlockPage = (props) => {
  return (
    <>
    <Helmet
    title='Admin Panel Mathces'>

    </Helmet>
      <PageLayout>
        <AdminPanel />
      </PageLayout>
    </>
  );
};

export default AdminPanelBlockPage;
