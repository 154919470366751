const MatchHelper = require('./match-helper');
const ImageStore = require('../stores/image-store')
const BookingAgencyStore = require('../stores/booking-agency-store')


class LogoHelper {
  constructor() {
    this.bettingCompanies = {
      '10Bet': '/logos/betting_companies/10Bet.svg',
      '888sport': 'https://res.cloudinary.com/dzykt0nza/image/upload/v1717158365/BetsCompaniesLogos/638527551643503163.png',
      '888Sport': 'https://res.cloudinary.com/dzykt0nza/image/upload/v1717158365/BetsCompaniesLogos/638527551643503163.png',
      'ATG': '/logos/betting_companies/ATG.svg',
      'BetAndWin': '/logos/betting_companies/bwin.svg',
      'BetAtHome': '/logos/betting_companies/betathome.svg',
      'Bet365': '/logos/betting_companies/bet365.svg',
      'Bet188Com': '/logos/betting_companies/188Bet.svg',
      'BetfairOdds': '/logos/betting_companies/Betfair.svg',
      'Bethard': '/logos/betting_companies/bethard.svg',
      'Betsafe': '/logos/betting_companies/Betsafe.svg',
      'BetsSon': '/logos/betting_companies/betsson.svg',
      'Betsson Sportsbook': '/logos/betting_companies/betsson.svg',
      'Betway': '/logos/betting_companies/Betway.svg',
      'Casinostugan': '/logos/betting_companies/Casinostugan.svg',
      'ComeOn': '/logos/betting_companies/comeon.svg',
      'Coolbet': '/logos/betting_companies/coolbet.svg',
      'Expekt': '/logos/betting_companies/expekt.svg',
      'Guts': '/logos/betting_companies/Guts.svg',
      'Hajper': '/logos/betting_companies/Hajper.svg',
      'Intervetten': '/logos/betting_companies/Intervetten.svg',
      'Ladbrokes': '/logos/betting_companies/Ladbrokes.svg',
      'Legolas': '/logos/betting_companies/Legolas.svg',
      'MrGreen': '/logos/betting_companies/MrGreen.svg',
      'NordicBet': '/logos/betting_companies/nordicbet.svg',
      'OddsetSweden': '/logos/betting_companies/SvenskaSpel.svg',
      'Paf': '/logos/betting_companies/Paf.svg',
      'Sbobet2': '/logos/betting_companies/sbobet.svg',
      'Snabbare': '/logos/betting_companies/Snabbare.svg',
      'SpeedyBet': '/logos/betting_companies/SpeedyBet.svg',
      'Strive': '/logos/betting_companies/Strive.svg',
      'SvenskaSpel': '/logos/betting_companies/SvenskaSpel.svg',
      'Svenska Spel': '/logos/betting_companies/SvenskaSpel.svg',
      'Unibet': '/logos/betting_companies/unibet.svg',
      'WilliamHill': '/logos/betting_companies/williamhill.svg',
      'William Hill': '/logos/betting_companies/williamhill.svg'
    };

    this.bettingCompaniesByIds = {
      117: '/logos/betting_companies/10Bet.svg',
      270: 'https://res.cloudinary.com/dzykt0nza/image/upload/v1695368576/BetsCompaniesLogos/o9xyouixobj8engf3kyq.png',
      14: '/logos/betting_companies/betathome.svg',
      23: '/logos/betting_companies/bet365.svg',
      475: '/logos/betting_companies/bethard.svg',
      70: '/logos/betting_companies/Betsafe.svg',
      72: '/logos/betting_companies/betsson.svg',
      303: '/logos/betting_companies/Betway.svg',
      392: '/logos/betting_companies/comeon.svg',
      519: '/logos/betting_companies/coolbet.svg',
      150: '/logos/betting_companies/expekt.svg',
      381: '/logos/betting_companies/Guts.svg',
      120: '/logos/betting_companies/nordicbet.svg',
      132: '/logos/betting_companies/SvenskaSpel.svg',
      141: '/logos/betting_companies/unibet.svg',
      554: '/logos/betting_companies/williamhill.svg',
      154: '/logos/betting_companies/williamhill.svg'
    };

    this.tournamentBackdropClass = {
      'e:tournament:42': 'champions-league',
      'e:tournament:73': 'europa-league',
      'e:tournament:76': 'world-cup-woman',
      'e:tournament:10195': 'world-cup', // World Cup Qualification UEFA
      'e:tournament:10196': 'world-cup', // World Cup Qualification CAF
      'e:tournament:10198': 'world-cup', // World Cup Qualification CONCACAF
      'e:tournament:292': 'european-championship',
      'e:tournament:9806': 'nations-league',
      'e:tournament:9807': 'nations-league',
      'e:tournament:9808': 'nations-league',
      'e:tournament:9809': 'nations-league',
      'e:tournament:47': 'premier-league',
      'e:tournament:132': 'fa-cup',
      'sr:tournament:18': 'efl-championship',
      'e:tournament:133': 'efl-cup',
      'e:tournament:87': 'la-liga',
      'e:tournament:55': 'seria-a',
      'e:tournament:54': 'bundesliga',
      'e:tournament:53': 'ligue-1',
      'e:tournament:67': 'allsvenskan',
      'e:tournament:168': 'superettan',
      'e:tournament:169': 'division-1',
      'e:tournament:169': 'division-1',
      'sr:tournament:80': 'svenska-cupen',
      'e:tournament:21': 'svenska-hockey-ligan',
      'e:tournament:22': 'hockeyallsvenskan',
      'e:tournament:9957': 'hockeyettan',
      'e:tournament:24': 'nhl',
      'e:tournament:9089': 'damallsvenskan',
      'e:tournament:48': 'efl-championship',
      'e:tournament:10283': 'hockeyettan-qualification',
      'e:tournament:313': 'euro-hockey-tour',
      'e:tournament:10216': 'europe-conference-league',
      'e:tournament:33': 'vm',
      'e:tournament:114': 'friendlies',
      'e:tournament:489': 'friendlies',
      'e:tournament:171': 'svenska-cupen',
      'e:tournament:247': 'community-shield',
      'e:tournament:74': 'uefa-supercup',
      'e:tournament:77': 'world-cup',
      'e:tournament:10199': 'world-cup-qualification-conmebol'
    };
  }

  getBettingCompanyLogoUrl(bettingCompany) {
    if(bettingCompany && bettingCompany.bookingAgencyId && BookingAgencyStore.getAgencyLogo(bettingCompany.bookingAgencyId)){
      return BookingAgencyStore.getAgencyLogo(bettingCompany.bookingAgencyId)
    }
    else if (typeof this.bettingCompanies[bettingCompany] === 'undefined') {
      return null;
    }

    return this.bettingCompanies[bettingCompany];
  }

  getBettingCompanyLogoUrlByName(bettingCompanyName) {
    if (typeof this.bettingCompanies[bettingCompanyName] === 'undefined') {
      return null;
    }
    return this.bettingCompanies[bettingCompanyName];
  }

  getBettingCompanyLogoUrlById(bettingCompanyId) {
    if (typeof this.bettingCompaniesByIds[bettingCompanyId] === 'undefined') {
      return null;
    }
    return this.bettingCompaniesByIds[bettingCompanyId];
  }

  getFallBackTeamLogo(){
    return ImageStore.getImage("fallback_image_teamlogo")
  }

  getTeamLogoSrc(teamId) {
    // if (!teamId) {
    //   return this.getFallBackTeamLogo();
    // }
    // return `/images/teamlogos/${teamId.split(':').join('_')}.svg`;
    return 'https://res.cloudinary.com/eyebeep/image/upload/s--M0RDIil0--/f_auto,h_160,q_auto,w_160/v1/Companies/%20118/637688494654143294';
  }

  getTeamLogoPngSrc = (teamId) => {
    if (!teamId) {
      return this.getFallBackTeamLogo();
    }
    return `/images/teamlogos/${teamId.split(':').join('_')}.png`;
  }

  getTeamLogoFromSportRadar(teamId) {
    if (!teamId) {
      return this.getFallBackTeamLogo();
    }

    return `https://ls.sportradar.com/ls/crest/big/${MatchHelper.getCleanSportRadarId(teamId)}.png`;
  }

  getTournamentBackdropClass(tournamentId) {
    if (typeof this.tournamentBackdropClass[tournamentId] === 'undefined') {
      return null;
    }

    return ['backdrop', this.tournamentBackdropClass[tournamentId]].join(' ');
  }

  getTournamentLogoClass(tournamentId) {
    if (typeof this.tournamentBackdropClass[tournamentId] === 'undefined') {
      return null;
    }
    return ['logo', this.tournamentBackdropClass[tournamentId]].join(' ');
  }
}

const helper = new LogoHelper();
export default helper;
