import React, { Component, useContext, useEffect, useState } from 'react';
import SportEventService from 'services/sport-event-service';
import TournamentService from 'services/tournament-standings-service';
import { AuthContext } from 'components/helpers/auth-context';
import LiveScoreMatchItem from 'components/block-elements/live-score-match-item/live-score-match-item';
import Modal from 'components/block-elements/modal/modal';
import styles from './admin-panel.module.less';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast, { Toaster } from 'react-hot-toast';
import Loader from 'components/block-elements/loader-css/loader-css';

const AdminPanel = (props) => {
  const auth = useContext(AuthContext);
  const [events, setEvents] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [pagesCount, setPagesCount] = useState(null);
  const [error, setError] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [search, setSearch] = useState("");
  const [tournaments, setTournaments] = useState(null);
  const [selectedTournaments, setSelectedTournaments] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const ClientSideOnlyLazy = React.lazy(() =>
    import("components/block-elements/admin-sport-events/admin-sport-events")
  )

  const [filter, setFilter] = useState({
    searchText: null,
    tournamentsListIds: null,
    date: null,
    page: 0,
  });

  const changeHandlerSearch = (event) => {
    setSearch(event.target.value);
  };

  const getFutureEvents = async (pageN) => {
    setLoading(true)
    await SportEventService.GetFutureSportEvents(filter)
      .then((res) => {
        setEvents(res.data);
        setPagesCount(Array.from(Array(Math.ceil(res.data.total / 20)).keys()));
        setLoading(false)
      })
      .catch((err) => {
        setError('You have not permissions to see admin panel!');
      });
    setLoading(false)
  };

  const getAllTournamets = async () => {
    await TournamentService.getAllTournamets()
      .then((res) => {
        setTournaments(res.data);
      })
      .catch((err) => {
        
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  }

  const searchClick = () => {
    setFilter({ ...filter, page: 0, searchText: search });
  };

  const clearSearch = () => {
    setFilter({ ...filter, page: 0, searchText: null });
    setSearch('');
  };

  const chagePage = async (event) => {
    setFilter({ ...filter, page: event.target.name });
  };

  useEffect(() => {
    if (auth.isAunthenticated) {
      getFutureEvents(filter.page);
    }
  }, [filter.page, filter.date, filter.tournamentsListIds, filter.searchText]);

  useEffect(() => {
    getAllTournamets();
    setStartDate(null);
  }, []);

  const openEditModal = (isOpen, props) => {
    setCurrentMatch(null);
    setCurrentMatch(props);
    setIsOpen(isOpen);
  };

  const onTournamentsSelect = (selectedList, selectedItem) => {
    setSelectedTournaments(selectedList);

    let tournamentsIds = [];
    if (selectedList && selectedList.length > 0) {
      selectedList.forEach((el) => {
        tournamentsIds.push(el.id);
      });
    }

    setFilter({ ...filter, tournamentsListIds: tournamentsIds, page: 0 });
  };

  const SetDateFilter = (date) => {
    setStartDate(date);

    setFilter({ ...filter, date: date, page: 0 });
  };

  const clearDateFilter = () => {
    setStartDate(null);
    setFilter({ filter, date: null, page: 0 });
  };

  const isSSR = typeof window === "undefined";

  const saveDataToast = () => {
    toast.success('Data changed successfully!');
    setIsOpen(false);
  };

  const clearTournamentsFilter = () => {
    setSelectedTournaments([]);
    setFilter({ filter, tournamentsListIds: null, page: 0 });
  };

  const setCurrentMatchCustomTvLogoWidth = (val) => {
    setCurrentMatch({...currentMatch, customTvLogoWidth: val});
  }

  const setCurrentMatchCustomTvLogoHeight = (val) => {
    setCurrentMatch({...currentMatch, customTvLogoHeight: val});
  }

  return (
    <>
      <div id="admin-events-main-element">
        <div className={styles.searchContainer}>
          <div className={styles.searchBlock}>
            <input
              name='searchText'
              placeholder='Search by (Ids and Names: Teams, Tournaments)'
              value={search}
              onInput={changeHandlerSearch}
              onChange={changeHandlerSearch}
              className={styles.input}
              type='text'></input>
            <button className={styles.searchButton} onClick={() => searchClick()}>
              Search
            </button>
            <button onClick={() => clearSearch()}>Clear</button>
          </div>
          <div>
            <div className={styles.sportSelectedBlock}>
              <DatePicker placeholderText='Date' selected={startDate} onChange={(date) => SetDateFilter(date)} />
              <button onClick={clearDateFilter}>Clear</button>
            </div>
            {tournaments && (
              <div className={styles.sportSelectedBlock}>
                <Multiselect
                  className={styles.sportSelect}
                  options={tournaments}
                  displayValue='name'
                  showCheckbox
                  placeholder={
                    selectedTournaments.length > 0 ? `${selectedTournaments.length} Tournaments select` : 'Tournaments'
                  }
                  hideSelectedList
                  selectedValues={selectedTournaments}
                  onSelect={onTournamentsSelect}
                  onRemove={onTournamentsSelect}
                />
                <button onClick={clearTournamentsFilter}>Clear</button>
              </div>
            )}
          </div>
        </div>
        {(events && !loading) &&
          events.data.map((e) => {
            return (
              <div key={e.id} onClick={() => openEditModal(true, e)}>
                <LiveScoreMatchItem showDateTimes={true} key={e.id} {...e} notLink />
              </div>
            );
          })}

          {loading && <Loader/>}

        <div className={styles.flexButtonGroup}>
          <div className={styles.buttonsGroups}>
            {pagesCount &&
              pagesCount.length > 0 &&
              pagesCount.map((el, i) => {
                return (
                  <button
                    className={`${el == filter.page ? styles.activePagButton : ''} ${styles.button}`}
                    name={el}
                    onClick={chagePage}>
                    {el + 1}
                  </button>
                );
              })}
          </div>
        </div>

        {isOpen && 
        <Modal>
            {!isSSR && (
            <React.Suspense fallback={<div />}>
              <ClientSideOnlyLazy 
                refreshPage={() => getFutureEvents(filter.page)} 
                setCurrentMatchCustomTvLogoHeight={setCurrentMatchCustomTvLogoHeight}
                setCurrentMatchCustomTvLogoWidth={setCurrentMatchCustomTvLogoWidth} 
                setIsOpen={closeModal} 
                saveDataToast={saveDataToast} 
                {...currentMatch}/>
            </React.Suspense>
          )}
          </Modal>}
        <Toaster position='top-left' reverseOrder={true} />
      </div>
    </>
  );
};

export default AdminPanel;
